import { customElement, html, LitElement, css, property } from 'lit-element';
import { connect } from "pwa-helpers";
import { store } from "../../redux/store";
import { Router } from '@vaadin/router';

import { styleMap } from 'lit-html/directives/style-map.js';

import anime from 'animejs';

import '@vaadin/vaadin-button';

import {
  onSendMessage,
  onReceiveMessage,
  updateFilter,
  clearCompleted
} from '../../redux/actions';
import { BaseView } from './BaseView';


@customElement('app-card' as any)
class AppDialog extends connect(store)(LitElement) {

  @property({ type: Object }) data = {};
  @property({ type: Number }) index = 0;
  @property({ type: Function }) next = null;

  isScrolling: any = false;
  scrollPos: any;

  constructor() {
    super();

    // document.addEventListener('wheel',
    //   this.mouseController, false);

  }

  mouseController = (event) => {

    // Clear our timeout throughout the scroll
    window.clearTimeout(this.isScrolling);

    const self = this;
    const e = event;

    // Set a timeout to run after scrolling ends
    this.isScrolling = setTimeout(function () {

      // Run the callback
      console.log('Scrolling has stopped.');
      self.scrollEventHandler(e.deltaY);

    }, 100);

    // if (event.deltaY > 100) {
    //   console.log(event);
    //   if (this.data['tap'])
    //     Router.go(this.data['tap']);
    // }
    return false;
  }

  scrollEventHandler(event) {
    console.log(event);

    if (event > 0) {
      if (this.data['tap'])
        Router.go(this.data['tap']);
    } else {
      // @ts-ignore
      window.history.back();
      console.log("back");

    }
    return;
  }

  connectedCallback() {

    // @ts-ignore

    // requestAnimationFrame(() => {
    //   this.runAnimation();
    // });

    super.connectedCallback();

  }


  runEnterAnimation() {

    // @ts-ignore
    var cards = this.shadowRoot.querySelectorAll('.card');

    anime({
      targets: cards,
      scale: [0.9, 1],
      easing: "easeOutExpo",
      delay: 700,
      duration: 1800
    });


    // @ts-ignore
    var elements = this.shadowRoot.querySelectorAll('.text');

    anime({
      targets: elements,
      translateX: [0, 0],
      translateY: [200, 0],
      translateZ: 0,
      opacity: [0, 1],
      scale: [1, 1],
      easing: "easeOutExpo",
      delay: anime.stagger(200, { start: 750 })
    });


    // @ts-ignore
    var pic = this.shadowRoot.querySelectorAll('.picture');

    if (this.data['id'] % 2 == 0) {
      anime({
        targets: pic,
        translateX: [2000, 0],
        opacity: [0, 0.2],
        easing: "easeOutExpo",
        delay: 700,
        duration: 1800
      });
    } else {
      anime({
        targets: pic,
        translateX: [-2000, 0],
        opacity: [0, 0.2],
        easing: "easeOutExpo",
        delay: 700,
        duration: 1800
      });
    }

  }
  runLeaveAnimation() {

    // @ts-ignore
    var elements = this.shadowRoot.querySelectorAll('.text');

    anime({
      targets: elements,
      translateX: [0, 0],
      translateY: [0, -100],
      translateZ: 0,
      opacity: [1, 0],
      scale: [1, 0.8],
      easing: "easeOutExpo",
      delay: anime.stagger(50, { start: 10 })
    });

    // @ts-ignore
    var pic = this.shadowRoot.querySelectorAll('.picture');
    anime({
      targets: pic,
      opacity: [0.2, 0],
      easing: "easeOutExpo",
      delay: 100
    });

  }

  static get styles() {

    return [
      css`
        :host{ 
          color: red; 
        }; `
      ,
      css`  
        .text {
          font-size: 2.0em;
          padding: 30px;
        }
        .center {
          text-align: center;
        }
        .title {
          font-size: 60px;
          font-weight: bold;
        };
      `,
      css`  
        .space {
          margin-left: 10px;
        };
      `,
      css`  
        .card { 
          display:flex;
          scale: 0.8
        };
      `,
      css`  
        .reverse {
          flex-direction: row-reverse;
         };
      `,
      css`  
        .col { 
          height: 100vh;
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          resize: vertical;
          overflow: auto;
        }
        .wide { 
          height: 100vh;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items:center;
          resize: vertical;
          overflow: auto;
        }
        .picture {
          opacity: 0;
          background: red 
        }
        .overlaytexture {
          height: 100%;
          background-image: linear-gradient(135deg, #a8a8a8 25%, transparent 25%, transparent 50%, #a8a8a8 50%, #a8a8a8 75%, transparent 75%, transparent 100%);
          background-size: 5.66px 5.66px;
        }
        .content{
          text-color: "666666";
          padding: 20px;
          max-width: 80%;
          resize: vertical;
          overflow: auto;
        }
        .widecontent{
          color: "666666";
          text-align: center;
          padding: 20px;
          max-width: 50%;
          resize: vertical;
          overflow: auto;
        }
      `,
      css`  
        .card_inactive {
          height: 100vh;
          display:none;
        };
      `
    ];
  }

  textToLetter(str = ' ') {

    return str;

    var r = <any>[];

    if (!isNaN(parseFloat(str))) {
      str = str + '';
    }

    for (var x = 0; x < str.length; x++) {
      var c = str.charAt(x);
      if (c == ' ') {
        r.push(html`<div class='space'> </div>`);
      } else {
        r.push(html`<div class='letter'>${c}</div>`);
      }
    }

    return html`${r}`;
  }

  public render() {

    var card = "card";
    if (this.data['id'] % 2 == 0) {
      card = "card reverse";
    }

    var styles = {};

    styles['card'] = {
      "background-color": this.data['background-color'] || "#f5f0ea",
      "color": this.data['color'] || "#666666",
      "font-size": this.data['font-size'] || "24px",
      "font-weight": this.data['font-weight'] || "normal",
    };

    styles['title'] = {
      "color": this.data['title-color'] || styles['card']['color'],
      "font-size": this.data['title-font-size'] || styles['card']['font-size'],
      "font-weight": this.data['title-font-weight'] || styles['card']['font-weight'],
    };

    styles['subtitle'] = {
      "color": this.data['subtitle-color'] || styles['card']['color'],
      "font-size": this.data['subtitle-font-size'] || styles['card']['font-size'],
      "font-weight": this.data['subtitle-font-weight'] || styles['card']['font-weight'],
    };

    styles['text'] = {
      "color": this.data['text-color'] || styles['card']['color'],
      "font-size": this.data['text-font-size'] || styles['card']['font-size'],
      "font-weight": this.data['text-font-weight'] || styles['card']['font-weight'],
    };



    var stylesPic = {
      "background-image": this.data['background-image'] || "url(https://placebear.com/200/300)",
      "background-size": "cover"
    };



    return html`
              ${this.index == 0 ?
        html`
                <div id="card_${this.data['id']}"  style=${styleMap(styles['card'])} class="${card}">
                  <div class="wide">       
                  <div class="widecontent">                              
                    <div class="center text" style=${styleMap(styles['title'])}>${this.textToLetter(this.data['title'])}</div>
                    <div class="center text" style=${styleMap(styles['subtitle'])}>${this.textToLetter(this.data['subtitle'])}</div>
                    <div class="center text" style=${styleMap(styles['text'])}>${this.textToLetter(this.data['text'])}</div>
                  </div>
                </div>
                </div>
                `:
        html`
                <div id="card_${this.data['id']}"  style=${styleMap(styles['card'])} class="${card}">
                  <div class="col picture" style=${styleMap(stylesPic)} ><div class = "overlaytexture"></div></div>
                  <div class="col">  
                    <div class="content">                              
                    <div class="text title" style=${styleMap(styles['title'])}>${this.textToLetter(this.data['title'])}</div>
                    <div class="text" style=${styleMap(styles['subtitle'])}>${this.textToLetter(this.data['subtitle'])}</div>
                    <div class="text" style=${styleMap(styles['text'])}>${this.textToLetter(this.data['text'])}</div>
                  </div>       
                   </div>
                </div>
                `}
            `;
  }
}
