import {
    ON_SEND_MESSAGE,
    UPDATE_FILTER,
    ON_RECEIVE_MESSAGE,
    CLEAR_COMPLETED
} from './actions';

const VisibilityFilters = { SHOW_ALL: "ALL" };

const INITIAL_STATE = {
    count: 0,
    data: {}
}

export const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case ON_SEND_MESSAGE:
            
            var d = state.data || {};
            d['messages'] = (d['messages'] || []).concat(action.data);

            return { ...state, data: d }


        case ON_RECEIVE_MESSAGE:

            var d = state.data || {};
            d['messages'] = (d['messages'] || []).concat(action.data);

            return { ...state, data: d }

        default:
            return state;
    }
}