import { customElement, html, LitElement, css, property } from 'lit-element';
import { connect } from "pwa-helpers";
import { store } from "../../redux/store";
import { Router } from '@vaadin/router';


// @ts-ignore
import logo from '../../assets/images/HYVEN_03.svg';
import anime from 'animejs';

import '@vaadin/vaadin-button';
import './app-card';

import {
  onSendMessage,
  onReceiveMessage,
  updateFilter,
  clearCompleted
} from '../../redux/actions';
import { BaseView } from './BaseView';
import { resolve } from 'dns';
import { exists } from 'fs';
import { reducer } from '../../redux/reducer';


@customElement('app-dialog' as any)
class AppDialog extends connect(store)(LitElement) {

  @property({ type: String }) cid = '1';
  @property({ type: Object }) dataList = {};
  @property({ type: Object }) data = {};
  @property({ type: Number }) actualCardIndex = 0;
  @property({ type: String }) proceed = "";
  @property({ type: Object }) pills = {};
  @property({ type: Object }) cards = {};


  open: boolean = false;
  timer: any;
  isScrolling: any = false;
  //actualCardIndex: number = 0;
  allCards: string[] = [];
  blockScrolling: boolean = false;
  animation: any;
  links: any;
  menu: any;




  constructor() {
    super();
    document.addEventListener('wheel', this.mouseController, { passive: false });
  }

  scrollSwitch(index) {

    // @ts-ignore
    let element = this.shadowRoot.getElementById('card_' + this.allCards[this.actualCardIndex]);
    if (element) {
      this.runSetNavPillsAnimation(this.allCards[this.actualCardIndex], false);
      // @ts-ignore
      element.runLeaveAnimation();
    }

    this.actualCardIndex = index;

    this.scrollTo(this.allCards[index]);

  }


  scrollTo(ref) {

    // @ts-ignore
    let card = this.shadowRoot.getElementById('card_' + ref);

    if (card) {

      card.scrollIntoView({ behavior: "smooth" });
      // @ts-ignore
      card.runEnterAnimation();
      this.runSetNavPillsAnimation(this.allCards[this.actualCardIndex], true);
    }

  }


  scrollWheelTo(ref) {

    if (this.blockScrolling)
      return;

    console.log("HM:   scrollTo(ref) ref: " + ref);

    // @ts-ignore
    let element = this.shadowRoot.getElementById('card_' + ref);
    // @ts-ignore
    let elementOffset = element.getBoundingClientRect().top;
    let scrollPosition = window.scrollY;
    let documentTop = document.documentElement.clientTop;
    let scrollOffset = elementOffset + scrollPosition - documentTop;

    this.blockScrolling = true;
    var animation = anime({
      targets: [document.documentElement, document.body],
      scrollTop: scrollOffset,
      duration: 1000,
      easing: 'easeInOutQuad'
    });

    // @ts-ignore
    element.runEnterAnimation();
    this.runSetNavPillsAnimation(this.allCards[this.actualCardIndex], true);

    animation.finished.then
      (this.stop.bind(this));

  }



  stop() {

    setTimeout(() => {
      this.blockScrolling = false;
      console.log("ende");
    }, 500);

  }

  mouseController = (e) => {


    e.preventDefault();

    if (this.blockScrolling) {
      return;
    }
    console.log("call mouse after ");

    if (e.deltaY < 0 && (this.actualCardIndex - 1) >= 0) {

      // @ts-ignore
      let element = this.shadowRoot.getElementById('card_' + this.allCards[this.actualCardIndex]);
      if (element)
        // @ts-ignore
        element.runLeaveAnimation();
      this.runSetNavPillsAnimation(this.allCards[this.actualCardIndex], false);


      this.actualCardIndex -= 1;
      console.log("goto" + this.actualCardIndex);
      this.scrollWheelTo(this.allCards[this.actualCardIndex]);

    } else if (e.deltaY > 0 && (this.actualCardIndex + 1) < this.allCards.length) {

      // @ts-ignore
      let element = this.shadowRoot.getElementById('card_' + this.allCards[this.actualCardIndex]);
      // @ts-ignore
      element.runLeaveAnimation();
      this.runSetNavPillsAnimation(this.allCards[this.actualCardIndex], false);

      this.actualCardIndex += 1;
      console.log("goto" + this.actualCardIndex);
      this.scrollWheelTo(this.allCards[this.actualCardIndex]);
    }


  }

  async connectedCallback() {

    // @ts-ignore
    this.cid = this.location.params.cid || this.cid;

    await this.fetchAll(this.cid);
    await this.fetchLinks();

    this.preRenderCards();
    this.preRenderPills();
    await this.preRenderMenu();

    requestAnimationFrame(() => {
      this.runAnimation();
      window.onresize = this.adjustIndicatorPos.bind(this);
      window.onkeydown = this.scrollToNextCard.bind(this);
    });
    //this.actualCard = this.cid;
    super.connectedCallback();

    this.updateComplete.then(() => {
      // @ts-ignore
      var card = this.shadowRoot.getElementById('card_' + this.allCards[0]);
      if (card) {
        this.scrollTo(this.allCards[0]);
      }
    });


  }


  async fetchAll(cid) {

    const res = await this.fetchAsync(cid);
    res['id'] = cid;

    var result;
    result = res;
    this.dataList[res['id']] = result;
    this.allCards.push(cid);

    if (result['tap']) {
      await this.fetchAll(result['tap']);
    } else {
      return;
    }

  }

  async fetchLinks() {

    const res = await this.fetchAsync("links");
    this.links = res;

  }

  runPulseAnimation() {

    // @ts-ignore
    //var elements = this.shadowRoot.querySelectorAll('.text');


    // @ts-ignore
    var elements = this.shadowRoot.querySelectorAll('.proceed');

    anime({
      targets: elements,
      scale: '2',
      easing: 'easeInOutQuad',
      direction: 'alternate',
      loop: 2
    });


  }

  toggleMenu(event) {

    // @ts-ignore
    let ham = this.shadowRoot.getElementById('hamburg');
    if (ham) ham.classList.toggle("checked");

    // @ts-ignore
    let menu = this.shadowRoot.getElementById('menu');

    // @ts-ignore
    let pills = this.shadowRoot.getElementById('pillscontainer');

    // @ts-ignore
    let indicator = this.shadowRoot.getElementById('pill_indicator');

    if (this.open) {

      anime.timeline({
        targets: menu,
        translateX: ['100%'],
        easing: 'easeInOutQuad',
      }).add({
        targets: [pills, indicator],
        rotateY: [{ value: "0deg" }],
        easing: 'easeInOutQuad',
      });

    } else {

      anime.timeline({
        targets: [pills, indicator],
        rotateY: [{ value: "90deg", duration: 100 }],
        easing: 'easeInOutQuad',
      }).add({
        targets: menu,
        translateX: ['-100%'],
        easing: 'easeInOutQuad',
      });

    }

    this.open = !this.open;
  }

  runAnimation() {

    // @ts-ignore
    //var elements = this.shadowRoot.querySelectorAll('.text');


    // @ts-ignore
    var elements = this.shadowRoot.querySelectorAll('.proceed');

    var ani = anime({
      targets: elements,
      translateX: [0, 0],
      translateY: [-300, 0],
      translateZ: 0,
      opacity: [0, 0.8],
      scale: [5, 1],
      easing: "easeOutExpo",
      delay: 10,
      duration: 2500
    });


  }


  adjustIndicatorPos() {

    // @ts-ignore
    let pill = this.shadowRoot.getElementById('pill_' + this.allCards[this.actualCardIndex]);

    // @ts-ignore
    let indicator = this.shadowRoot.getElementById('pill_indicator');
    // @ts-ignore
    var l = indicator.getBoundingClientRect().left;
    // @ts-ignore
    var t = indicator.getBoundingClientRect().top;

    if (l == 0 && pill) {
      l = pill.getBoundingClientRect().left;
      t = pill.getBoundingClientRect().top;
    }


    if (pill && indicator) {

      anime({
        targets: indicator,
        translateX: [pill.getBoundingClientRect().left],
        translateY: [pill.getBoundingClientRect().top],
        duration: 1500
      });
    }
  };

  runSetNavPillsAnimation(id, show) {



    // @ts-ignore
    //var elements = this.shadowRoot.querySelectorAll('.text');


    // @ts-ignore
    let pill = this.shadowRoot.getElementById('pill_' + id);

    // @ts-ignore
    let indicator = this.shadowRoot.getElementById('pill_indicator');
    // @ts-ignore
    var l = indicator.getBoundingClientRect().left;
    // @ts-ignore
    var t = indicator.getBoundingClientRect().top;

    if (l == 0 && pill) {
      l = pill.getBoundingClientRect().left;
      t = pill.getBoundingClientRect().top;
    }


    if (show && pill && indicator) {

      var t1 = anime.timeline({
        targets: indicator,
        translateX: [l, pill.getBoundingClientRect().left],
        translateY: [t, pill.getBoundingClientRect().top],
        easing: "easeInOutSine"
      });

      t1.add({
        targets: pill,
        opacity: [1, 0.4],
      })

    }

  }

  async fetchAsync(cid) {
    let data = await (await fetch('/assets/content/' + cid + '.json')).json();
    return data;
  }

  isElementInViewport(el) {
    var rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.top <= (window.innerHeight || document.documentElement.clientHeight)
    );
  }


  scrollToNextCard(event) {

    event.preventDefault();

    var isForward = true;
    var target = -1;

    if (event.keyCode &&  [37, 38].includes(event.keyCode)) {
      isForward = false;
    }

    if (isForward && this.allCards.length > this.actualCardIndex + 1) {
      target = this.actualCardIndex + 1;
    }

    if (!isForward && this.actualCardIndex > 0) {
      target = this.actualCardIndex - 1;
    }
    
    if (target>-1) {

      // @ts-ignore
      var card = this.shadowRoot.getElementById('card_' + this.allCards[target]);

      if (card) {
        this.actualCardIndex = target;
        card.scrollIntoView({ behavior: "smooth" });
      }
      // @ts-ignore
      card.runEnterAnimation();
      this.runSetNavPillsAnimation(this.allCards[target], true);

    }
  }


  static get styles() {
    return [
      css`
        :host{ 
          background-color: red;
          color: white; 
        } 
        .logo{
          height:80px;
          position: fixed;
          z-index: 200;
          top: 10px;
          left:10px;
        }
     ; `
      ,
      css`  
          .cardmaincontainer { 
            display:flex;
            flex-direction: row; 
          };
        `
      ,
      css`  
        .cardcontainer { 
          display:flex;
          flex:1;
          flex-direction: column; 
        };
      `     ,
      css`  
        .pillscontainer { 
          position: fixed;
          top: 150px;
          right: 24px;
          height: 60%;
          display:flex;
          flex-direction: column;
          justify-content: space-around;
          z-index: 1;
        };
      `,
      css`  
        .pill_passiv { 
          background:grey;
        };
      `
      ,
      css`  
        .pill { 
          background: black;
        };
      `,
      css`
      .circle { 
        cursor: pointer;
        display: inline-block;
        width: 40px;
        height: 40px; 
        };
      `,
      css`  
        .icon_hyven { 
          -webkit-mask: url(/assets/images/HYVEN-Logo-only-upper.svg);
          -webkit-mask-size: contain;
        };
      `,
      css`  
        .icon_hyven_indicator { 
          -webkit-mask: url(/assets/images/HYVEN-Logo-only-down.svg);
          -webkit-mask-size: contain;
          position: fixed;
          left: 0px;
          top: 0px;
          z-index: 100;
          background: black;
        };
      `,
      css`  
        .proceed { 
          position: fixed;
          bottom: 0;
          right: 0;
          width: 100%;
          cursor: pointer; 
          text-align: center;
          display: flex;
         align-items: center;
         justify-content: center;
        }; ` ,
      css`
        .proceed_false { 
          display: none;
        }; 
      `,
      css`
        .down_arrow {
          transform:rotate(90deg);
          font-size: 2em;
          margin-left: 10px;
        }
        .menu{
          position: fixed;
          z-index: 200;
          top: 90px;
          height: 100%;
          width: 50%;
          right: -50%;
          background-color: lightgrey;
          display: flex;
          flex-direction: column;
        }
        .menulink{
          font-size: 2em;
          color: #666666;
          margin-left: 10px;
        }
      `
      ,
      css`
      .container-hamburg{
        position: fixed;
        top: 0px;
        right: 0px;
      }
      .hamburg { 
        display: block;
        top: 20px;
        right: 20px;
        background: #555; width: 50px; height: 50px; 
        position: relative; 
        margin-left: auto; margin-right: auto;
        border-radius: 4px; 
        transition: border-radius .5s;
     }
     .line { 
      position: absolute; 
      left:10px;
      height: 2px; width: 30px; 
      background: #fff; border-radius: 2px;
      display: block; 
      transition: 0.5s; 
      transform-origin: center; 
      }
      .line:nth-child(1) { top: 12px; }
      .line:nth-child(2) { top: 24px; }
      .line:nth-child(3) { top: 36px; }
      
      .hamburg.checked .line:nth-child(1){
          transform: translateY(12px) rotate(-45deg);
      }
      
      .hamburg.checked .line:nth-child(2){
          opacity:0;
      }
      
      .hamburg.checked .line:nth-child(3){
          transform: translateY(-12px) rotate(45deg);
      } `
    ];
  }

  preRenderCards() {

    var r = <any>[];

    var id = this.cid;

    this.addCard(id, r);

    this.cards = html`${r}`;

  }


  private addCard(id: string, r: any, index: number = 0) {
    var data = this.dataList[id];
    var dat = JSON.stringify(data);
    var ref = data['tap'];
    var proceed = (ref && this.dataList[ref]);

    r.push(html`<app-card class="hidecard" id="card_${id}" data=${dat} index=${index}></app-card>`);
    if (proceed) {
      return this.addCard(ref, r, index + 1);
    }
    return;
  }

  preRenderPills() {

    var r = <any>[];

    var id = this.cid;

    for (let index = 0; index < this.allCards.length; index++) {
      r.push(html`<span class="icon_hyven circle pill" id="pill_${this.allCards[index]}" @click=${() => { this.scrollSwitch(index) }}></span>`);
    }

    this.pills = html`${r}`;

  }

  async preRenderMenu() {

    var r = <any>[];

    for (let index = 0; index < this.links['menu'].length; index++) {

      var id = this.links['menu'][index];
      var card = {};

      if (!this.dataList[id]) {
        card = await this.fetchAsync(id);
      } else {
        card = this.dataList[id];
      }
      var text = card['link'] || card['title'];

      var indexAll = this.allCards.indexOf(id);

      if (text && indexAll > -1) {
        var ind = indexAll.toString();
        r.push(html`<div class="menulink" @click=${() => { this.scrollSwitch(ind) }}>${text}</div>`);
      } else {
        var ind2 = id.toString();
        r.push(html`<div class="menulink" @click=${() => { this.scrollPush(ind2) }}>${text}</div>`);
      }
    }

    this.menu = html`${r}`;

  }
  scrollPush(ind: string) {

    this.toggleMenu(undefined);
    Router.go("/" + ind);

  }



  public render() {

    this.proceed = (this.actualCardIndex + 1 < this.allCards.length) ? 'proceed' : 'proceed_false';

    return html`
                <div class="menu" id="menu">${this.menu}</div>
                <img class="logo" src=${logo} />
                <span class="icon_hyven_indicator circle" id="pill_indicator"></span>
                <div class="cardmaincontainer">
                  <div class="cardcontainer">
                    ${this.cards}
                  </div>
                </div>
                <div class=${this.proceed} @mouseover=${this.runPulseAnimation} @click=${this.scrollToNextCard}><span class="down_arrow">&raquo;</span></div>      
                <div id="pillscontainer" class="pillscontainer">
                   ${this.pills} 
                </div>      
                <div class="container-hamburg">
                  <div class="hamburg" @click=${this.toggleMenu}>
                      <span class="line"></span>
                      <span class="line"></span>
                      <span class="line"></span>
                    </div>
                    <div class="space-hamburg">
                      </div>
                </div>
            `;
  }
}
