import 'reflect-metadata';

import { Router } from '@vaadin/router';
import './components/views/app-dialog';
import './components/views/view-intro';
 

let booted = false; 

function bootstrap() {

  if (booted) return;
  booted = true;
  initRouter();
}


function initRouter() {
  const router = new Router(document.getElementById('outlet'));

  router.setRoutes([
    {
      path: '/',
      component: 'view-intro',
      // @ts-ignore
      animate: true
    },
    // {
    //   path: '/:cid',
    //   component: 'app-dialog',
    //   animate: true
    // }
  ]);
}

window.addEventListener('WebComponentsReady', bootstrap);

document.createElement = Document.prototype.createElement;
