import {
  LitElement, html, customElement, css
} from 'lit-element';

import anime from 'animejs';

// @ts-ignore
import logo from '../../assets/images/HYVEN_01_w.svg';
// @ts-ignore
import shape from '../../assets/images/shape.png';
import { BaseView } from './BaseView';


@customElement('view-intro')
export class ViewIntro extends LitElement {

  // constructor() {
  //   super();

  // }

  // createRenderRoot() {
  //   return super.createRenderRoot();
  // }

  runAnimation() {

    // @ts-ignore
    const footer = this.shadowRoot.querySelectorAll('.intro_footer');
    // @ts-ignore
    const logo = this.shadowRoot.querySelectorAll('.intro_logo');
    // @ts-ignore
    const text = this.shadowRoot.querySelectorAll('.intro_headline');

    anime.timeline({
      targets: logo,
      duration: 2000,
      opacity: '1',
      easing: 'easeInOutQuad'
    }).add({
      targets: text,
      duration: 5000,
      opacity: '1',
      easing: 'easeInOutQuad'
    }).add({
      targets: footer,
      duration: 4000,
      opacity: '1',
      easing: 'easeInOutQuad'
    });


  }

  async connectedCallback() {

    requestAnimationFrame(() => {
      this.runAnimation();
    });

    super.connectedCallback();

  };

  static get styles() {
    return [
      css`
        :host{ 
          background-color: red;
          color: white; 
        } 
        .intro {
          height: 100vh;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          min-width: 300px;
        }
        .intro_headline {
          color: #f5f5f5;
          opacity: 0;
          text-align: center;
          font-size: x-large;
        }
        .intro_logo {
          max-width: 50%;
          opacity: 0;
          max-height: 300px;
        }
        .intro_footer {
          width: 100%;
          opacity: 0;
          margin-bottom: -10px;
        }
     ; `]
  };


  render() {
    return html`
                    <div class="intro">
                      <div>&nbsp;</div>
                      <div class="intro_headline">
                        <div>&nbsp;</div>
                        <div>Providing the smartest zero emission</div>
                        <div>fly & drive mobility solution besides beaming.</div>
                        <div>&nbsp;</div>
                      </div>
                      <img class="intro_logo" src=${logo}>
                      <img class="intro_footer" src=${shape}>
                    </div>
                `;
  }
}