const { DirectLine } = require('botframework-directlinejs');

import { store } from "../redux/store";
import {
    onSendMessage,
    updateFilter,
    clearCompleted,
    onReceiveMessage
} from '../redux/actions';

class BotService {
    //logs: any[];

    directLine = new DirectLine({
        secret: "t1SnOvZu0KE.NCO-0vAFdl8O-H2PfbBNTjDoYYGzUY_xZHnPMcDPFBw"
    });

    constructor() {

        this.directLine.activity$
        //.filter(activity => activity.from.id === 'immo-bot')
            .subscribe(
                activity => store.dispatch(onReceiveMessage(activity)));
    }


    postActivity(message) {
        this.directLine.postActivity(message).subscribe(
            id => console.log("Posted message " + message + " , assigned ID ", id),
            error => console.log("Error posting activity", error)
        );


    }

}

class Singleton {
    static instance: BotService;

    constructor() {
        if (!Singleton.instance) {
            Singleton.instance = new BotService();
        }
    }

    getInstance() {
        return Singleton.instance;
    }

}

module.exports = Singleton;