var BotService = require("../service/BotService");

export const ON_SEND_MESSAGE = 'ON_SEND_MESSAGE';
export const ON_RECEIVE_MESSAGE = 'ON_RECEIVE_MESSAGE';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const CLEAR_COMPLETED = 'CLEAR_COMPLETED';


export const onSendMessage = message => {
    var bot = new BotService().getInstance();
    bot.postActivity(message);

    //const m = new Message(new Date(), '1', '2');

    return {
        type: ON_SEND_MESSAGE,
        data: message
    }
};


export const onReceiveMessage = (message) => {
    return {
        type: ON_RECEIVE_MESSAGE,
        data: message
    };
};

export const updateFilter = filter => {
    return {
        type: UPDATE_FILTER,
        filter
    };
};

export const clearCompleted = () => {
    return {
        type: CLEAR_COMPLETED
    };
};